import React from "react";

export default class Apple extends React.Component {
  componentDidMount() {
    window.location.replace(
      "https://apps.apple.com/app/metalstorm/id1461423243"
    );
  }

  render() {
    return <div />;
  }
}
